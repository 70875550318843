import React, {useState} from 'react'
import axios from 'axios'

import {CKEditor} from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { editorConfig } from '../../config/options';

export default ({isOwner, ochranaText}) => {
        ClassicEditor.defaultConfig = editorConfig
        const [text, setText] = useState(ochranaText)
        const updateText = async () => {
                await axios.put(`${process.env.REACT_APP_BACKEND_URL}/home/update-law/`, {
                text,
                identificator: 'ochrana'
                }).then(res => console.log(res.data))
                .catch(err => console.error(err))
        }
        return (
                isOwner ?
                <CKEditor
                editor={ClassicEditor}
                data={ochranaText}
                onChange={(event, editor) => {
                        const data = editor.getData()
                        setText(data)
                }}
                onBlur={() => updateText()}
                />
                :
                <div dangerouslySetInnerHTML={{__html: ochranaText}}></div>
        )
}
