import React, { useEffect, useState } from 'react'
import { useParams } from "react-router-dom";
import axios from "axios"
import Helmet from 'react-helmet'

import ViewShop from "../ViewShop"
import Spinner from "react-bootstrap/Spinner";

export default ({userId, isOwner}) => {
    const {shopUrl} = useParams()
    const [isUrlAvailible, setIsUrlAvailible] = useState(true)
    const [pageData, setPageData] = useState({})
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/page/link/${shopUrl}`)
            .then((res) => res.data ? setPageData(res.data) : setPageData({}))
            .catch((err) => err && console.log(err))
            .then(() => {
                if (pageData._id) setIsUrlAvailible(false)
                setLoading(false)
            })
    }, [])

    const getImage = (image) => {
        try {
          const img = `https://jakaibucket.s3.eu-central-1.amazonaws.com/${image.replace(/_/g, '-')}`
          return img;
        } catch {
          return null;
        }
    };

    return (
        loading ? 
        <Spinner
          style={{ marginLeft: "49%", marginTop: "20%" }}
          animation="border"
        /> :
        (isUrlAvailible && pageData._id) ?
        <>
            <Helmet>
                <title>{pageData.pageName}</title>
                <meta name="description" content={(pageData.description && pageData.description.length > 0) ? pageData.description.replace(/<p>(.*)<\/p>.*/, '$1') : '' } />

                <meta itemprop="name" content={pageData.pageName} />
                <meta itemprop="description" content={(pageData.description && pageData.description.length > 0) ? pageData.description.replace(/<p>(.*)<\/p>.*/, '$1') : '' } />
                <meta itemprop="image" content={getImage(pageData.logoImage)} />

                <meta property="og:url" content={`${process.env.REACT_APP_BACKEND_URL}/${pageData.url}`} />
                <meta property="og:type" content="website" />
                <meta property="og:title" content={pageData.pageName} />
                <meta property="og:description" content={(pageData.description && pageData.description.length > 0) ? pageData.description.replace(/<p>(.*)<\/p>.*/, '$1') : '' } />
                <meta property="og:image" content={getImage(pageData.logoImage)} />


                <meta name="twitter:card" content="summary_large_image" />
                <meta name="twitter:title" content={pageData.pageName} />
                <meta name="twitter:description" content={(pageData.description && pageData.description.length > 0) ? pageData.description.replace(/<p>(.*)<\/p>.*/, '$1') : '' } />
                <meta name="twitter:image" content={getImage(pageData.logoImage)} />

            </Helmet>
            <ViewShop pageData={pageData} isOwner={isOwner} userId={userId} /> 
        </> :
        <h5 style={{ marginTop: "20%" }} className="text-center">Pod adresou https://jakai.sk/{shopUrl} ešte neexistuje žiadna stránka.</h5>
        
    )
}