import React, {useState} from 'react';
import CreateShop from "./CreateShop";
import Reorder from './Reorder';
import axios from 'axios'

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";

import { Link } from "react-router-dom";

import logoDefault from '../../logo-default.jpg'
import { MdDelete } from "react-icons/md";

export default ({userData, pageData, category, setRefresh}) => {
    const [showCreateShop, setShowCreateShop] = useState(false)
    const [isHovered, setIsHovered] = useState("")
    const [refreshOrder, setRefreshOrder] = useState(true)
    const [reallyDelete, setReallyDelete] = useState('')

    const deleteCard = (id,e) => {
        e.stopPropagation()
        if (reallyDelete === id) {
            axios
                .delete(
                `${process.env.REACT_APP_BACKEND_URL}/page/${id}`
                )
                .then(() => setRefresh())
                .catch((err) => err && console.log(`Error ${err}`))
                .then(() => setReallyDelete(''));
        } else {
            setReallyDelete(id)
        }
      };

    const getImage = (image) => {
        try {
          const img = `https://jakaibucket.s3.eu-central-1.amazonaws.com/${image.replace(/_/g, '-')}`
          return img;
        } catch {
          return null;
        }
      };

    const showShops = () => {
        const activePages = pageData.filter(page => page.active)
        const createSortIndexes = activePages.map((block,i) => {
            if (block.position >= 0) {
                return block
            } 
            return {...block, position: i}
        })
        const sortedPages = createSortIndexes.sort((a,b) => a.position - b.position)
        return sortedPages.map((shop, i) => {
            const { _id, pageName, url, logoImage, pageType, externalLink, position } = shop
            const isExternal = externalLink && externalLink.length > 5 && !userData.isOwner
            console.log(externalLink)
            const chooseImage = logoImage
            const handleMouseOver = () => {
                let hoverObj = {}
                hoverObj[_id] = 'none'
                setIsHovered({...isHovered, ...hoverObj})
            }
            const handleMouseLeave = () => {
                setIsHovered('')
            }
            return (
                <Col className={`mb-4 ${userData.isOwner ? 'mt-4' : ''}`} md={4} key={_id} style={{height: "410px", maxWidth: '350px',  minWidth: '300px', margin: '0 auto', position: 'relative'}} >
                    {userData.isOwner &&
                        <Reorder pageId={_id} setRefreshOrder={setRefreshOrder} setRefresh={setRefresh} originalPages={sortedPages} />
                    }
                    {userData.isOwner &&
                    <Button
                        onClick={(e) => deleteCard(_id, e)}
                        style={{
                            position: "absolute",
                            width: reallyDelete !== _id ? "40px" : 'auto',
                            height: reallyDelete !== _id ? "40px" : 'auto',
                            top: "40px",
                            zIndex: "+500",
                            right: reallyDelete !== _id ? 'unset' : '0'
                        }}
                        variant="outline-danger"
                        >
                            {reallyDelete === _id ? '!! NAOZAJ VYMAZAT? !!' : <MdDelete style={{ fontSize: "150%", margin: "0 0 15px -5px" }} />}
                    </Button>}
                    <Link target={isExternal ? "_blank" : ""} to={isExternal ? `/presmerovanie?smerom=${externalLink}` : `/${url}`}>
                        <Card className={`h-100`} 
                            onMouseEnter={() => handleMouseOver()} 
                            onMouseLeave={() => handleMouseLeave()} 
                            style={{ textAlign:"center", color: '#333333' }} 
                        id={_id} >
                            <Card.Img className={`${isHovered[_id] === 'none' ? 'scale-out' : 'scale-in'}`} 
                                style={{height: '65%', width: '100%', objectFit: 'cover'}} src={getImage(chooseImage) ? getImage(chooseImage) : logoDefault} />
                            <Card.Body>
                                    <Card.Title>
                                        <h4>
                                            {pageName.toUpperCase()}
                                        </h4>
                                        {pageType &&<h5>{pageType}</h5>}
                                    </Card.Title>
                            </Card.Body>
                        </Card>
                    </Link>
                </Col>
            )
        })
    }

    const showHiddenShops = () => {
        const hiddenPages = pageData.filter(page => !(page.active))
        return hiddenPages.map(shop => {
            const { _id, pageName, url, logoImage, pageType, externalLink } = shop
            const isExternal = externalLink && externalLink.length > 5 && !userData.isOwner
            const chooseImage = logoImage
            const handleMouseOver = () => {
                let hoverObj = {}
                hoverObj[_id] = 'none'
                setIsHovered({...isHovered, ...hoverObj})
            }
            const handleMouseLeave = () => {
                setIsHovered('')
            }
            return (
                <Col className="mb-4" md={4} key={_id} style={{height: "410px", maxWidth: '350px',  minWidth: '300px', margin: '0 auto'}} >
                    {userData.isOwner &&
                    <Button
                        onClick={(e) => deleteCard(_id, e)}
                        style={{
                            position: "absolute",
                            width: reallyDelete !== _id ? "40px" : 'auto',
                            height: reallyDelete !== _id ? "40px" : 'auto',
                            zIndex: "+500",
                            right: reallyDelete !== _id ? 'unset' : '0'
                        }}
                        variant="outline-danger"
                        >
                            {reallyDelete === _id ? '!! NAOZAJ VYMAZAT? !!' : <MdDelete style={{ fontSize: "150%", margin: "0 0 15px -5px" }} />}
                    </Button>}
                    <Link target={isExternal ? "_blank" : ""} to={isExternal ? {pathname: externalLink} : `/${url}`}>
                        <Card className={`h-100`} 
                            onMouseEnter={() => handleMouseOver()} 
                            onMouseLeave={() => handleMouseLeave()} 
                            style={{ textAlign:"center", color: '#333333' }} 
                        id={_id} >
                            <Card.Img className={`${isHovered[_id] === 'none' ? 'scale-out' : 'scale-in'}`} 
                                style={{height: '65%', width: '100%', objectFit: 'cover'}} src={getImage(chooseImage) ? getImage(chooseImage) : logoDefault} />
                            <Card.Body>
                                    <Card.Title>
                                        <h4>
                                            {pageName.toUpperCase()}
                                        </h4>
                                        {pageType &&<h5>{pageType}</h5>}
                                    </Card.Title>
                            </Card.Body>
                        </Card>
                    </Link>
                </Col>
            )
        })
    }

    return (
        <Container className="pb-4">
            <Row>
                {userData.isOwner && 
                <Col className="text-center">
                    {showCreateShop ? 
                    <CreateShop position={pageData.filter(page => page.active).length} userData={userData} category={category} /> :
                    <Button style={{marginTop: "20px"}} variant="dark" onClick={() => setShowCreateShop(true)}>Pridať</Button>
                    }
                </Col>}
            </Row>
            <div className="text-center py-4">
                <hr className="d-none d-md-block col-md-3" />
                <h1 >{(category.replace(/[-]/g, ' a ')).toUpperCase()}</h1>
                <hr className="d-none d-md-block col-md-3" />
            </div>
            <Row className="mt-4">
                {showShops()}
            </Row>
            {userData.isOwner && 
            <>
                <Row className="mt-4">
                    <hr className="d-none d-md-block col-md-3" />
                    <h1>NEPUBLIKOVANÉ</h1>
                    <hr className="d-none d-md-block col-md-3" />
                </Row>
                <Row className="mt-4">
                    {showHiddenShops()}
                </Row>
            </>}
        </Container>
    )
}