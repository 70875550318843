import React from 'react'
import axios from 'axios'

import {GoArrowUp, GoArrowDown} from 'react-icons/go'
import Col from 'react-bootstrap/Col'

export default ({originalBlocks, setRefresh, blockId, setIsLoading}) => {
    const changePosition = async (direction) => {
        setIsLoading(prevState => !prevState)
        const currIndex = originalBlocks.findIndex(elem => elem._id === blockId)
        const newIndex = direction === 'minus' ?
            currIndex - 1 :
            currIndex + 1 ;
        const element = originalBlocks.splice(currIndex, 1);
        originalBlocks.splice(newIndex, 0, ...element);
        originalBlocks.forEach((val, i) => val.position = i)
        axios.post(`${process.env.REACT_APP_BACKEND_URL}/blocks/edit-blocks-positions/`, {
            blocksArray: originalBlocks.map(val => ({_id: val._id, position: val.position}))
        })
            .then(res => {
                console.log(res.data)
                setRefresh(prevState => !prevState)
                
            })
            .catch(err => console.log(err.message))
    }
    const iconStyles = {
        fontSize: '200%',
        color: '#AE1865',
        cursor: 'pointer',
        margin: '0px 5px'
    }
    return (
        <Col className="text-center" xs={12}>
            <GoArrowUp style={iconStyles} onClick={() => changePosition('minus')} />
            <GoArrowDown style={iconStyles} onClick={() => changePosition('plus')} />
        </Col>
    )
}