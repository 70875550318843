import React, {useEffect, useState} from 'react';
import axios from 'axios';

import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

import ObchodnePodmienky from './ObchodnePodmienky';
import OchranaSukromia from './OchranaSukromia';
import ReklamacnyPoriadok from './ReklamacnyPoriadok';
import Doprava from './Doprava';

export default ({showLawPopup, setShowLawPopup, isOwner}) => {
    const [lawText, setLawText] = useState({})
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BACKEND_URL}/home/`)
            .then(res => setLawText(res.data))
            .catch((err) => console.error(err))
    }, [])
        
    return (
        <Modal size="lg" show={showLawPopup !== ''} onHide={() => setShowLawPopup('')}>
            <Modal.Body className="text-center" style={{fontSize: "90%", backgroundColor: 'whitesmoke'}}>
                {showLawPopup === 'obchodne' && <ObchodnePodmienky isOwner={isOwner} obchodneText={lawText.obchodne} />}
                {showLawPopup === 'gdpr' && <OchranaSukromia isOwner={isOwner} ochranaText={lawText.ochrana} />}
                {showLawPopup === 'reklamacny' && <ReklamacnyPoriadok isOwner={isOwner} reklamacneText={lawText.reklamacne} />}
                {showLawPopup === 'doprava' && <Doprava isOwner={isOwner} platbaText={lawText.platba} />}
            </Modal.Body>
            <Modal.Footer className="justify-content-center">
                <Button variant="dark" onClick={() => setShowLawPopup('')}>
                    Zavrieť
                </Button>
            </Modal.Footer>
        </Modal>
    )
}